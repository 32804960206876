* {
  box-sizing: border-box;
  text-align: center;
}

body {
  background: rgb(232,37,20);
  background: linear-gradient(90deg, rgba(232,37,20,1) 0%, rgba(212,141,10,1) 14%, rgba(214,235,30,1) 28%, rgba(46,235,30,1) 42%, rgba(30,142,235,1) 60%, rgba(127,30,235,1) 76%, rgba(235,30,223,1) 100%);
}

.App {
  max-width: 770px;
  width: 60vw;
  margin: 50px auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: dodgerblue;
  padding: 50px;
  border-radius: 10px;
}

.hero-image {
  border-radius: 100%;
}

.single-link {
  width: 42vw;
  max-width: 540px;
  display: flex;
  justify-content: space-between;
  background-color: lightblue;
  padding: 15px 30px;
  border-radius: 40px;
  font-weight: bold;
}

.single-link-a {
  text-decoration: none;
  color: black;
  margin: 10px 0;
}

.header {
  margin-bottom: 20px;
  color: white;
}

@media (max-width: 700px) {
  .App {
    width: 80vw;
  }

  .single-link {
    width: 70vw;
  }
}

.live-text {
  color: red;
}